import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../assets/images/logo.png';
import Button from '../Button';
import Link from "gatsby";

import fonts from '../../css/fonts.css';
import style from '../../css/header.css';

const Header = () => (
  <header className="fixed md:relative top-0 bg-white shadow">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <a className="px-4" href="/">
        <div className="flex items-center text-2xl">
            <div className="w-12 mr-3">
              <img src={LogoIcon} />
            </div>
          <div className='text font font-bold'>CICO</div>
        </div>
      </a>

      <div className="flex mt-4 sm:mt-0">
        <a className="hidden md:block px-4" href="/">
          Početna
        </a>
        <a className="px-4" href="/o-nama">
          O nama
        </a>
        <a className="px-4" href="/kategorije">
          Proizvodi
        </a>
        <a className="px-4" href="https://orca-grupa.hr/">
          Orca grupa
        </a>
      </div>
      <div className="hidden md:block">
        <a className="px-4" href="/kontakt">
          <Button className="text-sm" >Kontakt</Button>
        </a>
      </div>
    </div>
  </header>
);

export default Header;
