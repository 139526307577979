import React from 'react';

import office from './../../assets/images/lnr-apartment.svg';
import clock from './../../assets/images/lnr-clock.svg';
import phone from './../../assets/images/lnr-phone-handset.svg';
import mail from './../../assets/images/lnr-envelope.svg';

import style from './../../css/footer.css';

const Footer = () => (
  <footer className="py-16 px-3 mt-48 text-gray-800">

    
<div className="flex-col md:hidden container mx-auto py-16 px-3 mt-12 text-gray-800 ">
      <div className="flex -mx-3">
        <div className="flex-1 flex-col px-3">
          <div className='image-container'>
            <img src={office}></img>
            <h2 className='text text-2xl font-bold'>Ured</h2>
          </div>
          <div className='text-container'>
            <p>ORCA GRUPA d.o.o.</p>
            <p>Gradišćanska ulica 26</p>
            <p>10 000 Zagreb</p>
            <p>Hrvatska</p>
            <p>OIB: 26876191863</p>
          </div>
        </div>
        <div className="flex-1 px-3">
          <div className="flex flex-col mb-5">
            <div className='image-container'>
              <img src={clock}></img>
              <h2 className='text text-2xl font-bold'>Radno vrijeme</h2>
            </div>
            <div className='text-container'>
              <p>Radnim danom</p>
              <p>09-17 sati</p>
            </div>
          </div>
          <div className="flex flex-col mb-5">
            <div className='image-container'>
              <img src={mail}></img>
              <h2 className='text text-2xl font-bold'>Email</h2>
            </div>
            <div className='text-container'>
              <p>info@orca-grupa.hr</p>
            </div>
          </div>
          <div className="flex flex-col mb-5">
            <div className='image-container'>
              <img src={phone}></img>
              <h2 className='text text-2xl font-bold'>Telefon</h2>
            </div>
            <div className='text-container'>
              <p>+385 99 376 8850</p>
            </div>
          </div>
        </div>
      </div>
      <div className="px-3 mb-8">
        <h2 className="text-lg font-semibold">CICO SLT</h2>
        <p className="mt-5">
          Bilo da se radi o smanjenju škripe u krivuljama u željezničkom prometu, 
          otpuštanju zaglavljenih vijaka, smanjenju otpora na klizanje i trenju, 
          poboljšanju zaštite od korozije, smanjenju potrošnje energije, 
          povećanju operativne pouzdanosti i učinkovitosti: <br/>
          CICO SLT nudi vam širok raspon mogućnosti optimizacije.</p>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Poveznice</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="https://codebushi.com">O nama</a>
          </li>
          <li>
            <a href="https://codebushi.com">Proizvodi</a>
          </li>
          <li>
            <a href="https://codebushi.com">Kontakt</a>
          </li>
          <li>
            <a href="https://codebushi.com">Orca Grupa</a>
          </li>
        </ul>
      </div>
    </div>


    <div className="hidden md:flex container mx-auto py-16 px-3 mt-12 text-gray-800 ">
      <div className="flex -mx-3">
        <div className="px-3 flex-1">
          <h2 className="text-lg font-semibold">CICO SLT</h2>
          <p className="mt-5">
            Bilo da se radi o smanjenju škripe u krivuljama u željezničkom prometu, 
            otpuštanju zaglavljenih vijaka, smanjenju otpora na klizanje i trenju, 
            poboljšanju zaštite od korozije, smanjenju potrošnje energije, 
            povećanju operativne pouzdanosti i učinkovitosti: <br/>
            CICO SLT nudi vam širok raspon mogućnosti optimizacije.</p>
        </div>
        
        <div className="flex-1 flex-col px-3">
          <div className='image-container'>
            <img src={office}></img>
            <h2 className='text text-2xl font-bold'>Ured</h2>
          </div>
          <div className='text-container'>
            <p>ORCA GRUPA d.o.o.</p>
            <p>Gradišćanska ulica 26</p>
            <p>10 000 Zagreb</p>
            <p>Hrvatska</p>
            <p>OIB: 26876191863</p>
          </div>
        </div>
        <div className="flex-1 px-3">
          <div className="flex flex-col mb-5">
            <div className='image-container'>
              <img src={clock}></img>
              <h2 className='text text-2xl font-bold'>Radno vrijeme</h2>
            </div>
            <div className='text-container'>
              <p>Radnim danom</p>
              <p>09-17 sati</p>
            </div>
          </div>
          <div className="flex flex-col mb-5">
            <div className='image-container'>
              <img src={mail}></img>
              <h2 className='text text-2xl font-bold'>Email</h2>
            </div>
            <div className='text-container'>
              <p>info@orca-grupa.hr</p>
            </div>
          </div>
          <div className="flex flex-col mb-5">
            <div className='image-container'>
              <img src={phone}></img>
              <h2 className='text text-2xl font-bold'>Telefon</h2>
            </div>
            <div className='text-container'>
              <p>+385 99 376 8850</p>
            </div>
          </div>
        </div>
        <div className="flex-1 px-3">
          <h2 className="text-lg font-semibold">Poveznice</h2>
          <ul className="mt-4 leading-loose">
            <li>
              <a href="/o-nama">O nama</a>
            </li>
            <li>
              <a href="/kategorije">Proizvodi</a>
            </li>
            <li>
              <a href="kontakt">Kontakt</a>
            </li>
            <li>
              <a href="https://orca-grupa.hr/">Orca Grupa</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
